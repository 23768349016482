import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import RTL from "../views/Rtl.vue";
import Notifications from "../views/Notifications.vue";
import Profile from "../views/Profile.vue";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import Students from "../views/Students/Students.vue";
import SendEmails from "../views/SendEmails/SendEmails.vue";
import EmployeeHourSalary from "../views/EmployeeHourSalary/EmployeeHourSalary.vue";
import AttendanceHourEmployee from "../views/AttendanceHourEmployee/AttendanceHourEmployee.vue";
import DeductionPerHour from "../views/DeductionPerHour/DeductionPerHour.vue";
import IssuedSalariesPerHour from "../views/IssuedSalariesPerHour/IssuedSalariesPerHour.vue";
import Treasury from "../views/Treasury/Treasury.vue";
import Parents from "../views/Parents/Parents.vue";
import Salaries from "../views/Salaries/Salaries.vue";
import AbsenceDeduction from '../views/AbsencesDeduction/AbsenceDeduction.vue';
import ManageSalaries from "../views/ManageSalaries/ManageSalaries.vue";
import Users from "../views/Users/Users.vue";
import Attendances from "../views/Attendances/StudentAttendances.vue";
import Employees from "../views/Employees/Employees.vue";
import PermissionEmployee from "../views/PermissionEmployee/PermissionEmployee.vue";
import EmployeeType from "../views/EmployeeType/EmployeeType.vue";
import EmployeeDetails from "../views/EmployeeDetails/EmployeeDetails.vue";
import SummerCamp from "../views/SummerCamp/SummerCampAdd.vue";
import SummerCampData from "../views/SummerCamp/SummerCampData.vue";
import Classes from "../views/Classes/Classes.vue";
import Departments from "../views/Departments/Departments.vue";
import SchoolSchedule from "../views/SchoolSchedule/SchoolSchedule.vue";
import TeacherType from "../views/TeacherType/TeacherType.vue";
import ClassesSections from "../views/ClassesSections/ClassesSections.vue";
import StudentsCards from '../views/StudentsCards/StudentsCards.vue';
import SalaryShow from '../views/SalaryShow/SalaryShow.vue';
import LogsCheck from '../views/LogsCheck/LogsCheck.vue';
import SubscriptionFee from '../views/SubscriptionFee/SubscriptionFee.vue';
import TreasuryTransactions from '../views/TreasuryTransactions/TreasuryTransactions.vue';
import Prediction from '../views/Prediction/Prediction.vue';
import FinancialDocument from '../views/FinancialFees/FinancialDocument.vue';
import FinancialDocumentPrint from '../views/FinancialDocumentPrint/FinancialDocumentPrint.vue';
import ImportAttendance from '../views/ImportAttendance/ImportAttendance.vue';
import HealthFile from '../views/HealthFile/HealthFile.vue';
import AllHealthFiles from '../views/HealthFile/AllHealthFiles.vue';
import ActivityLog from '../views/ActivityLog/ActivityLog.vue';
import ParentWallet from '../views/ParentWallet/ParentWallet.vue';
import Exams from "../views/Exams/Exams.vue";
import Buses from "../views/Buses/Buses.vue";
import kitchen from "../views/kitchen/kitchen.vue";
import NotFound from "../views/NotFound/NotFound.vue";
import ExtraEmployeeData from "../views/ExtraEmployeeData/ExtraEmployeeData.vue";
import kitchenSales from "../views/kitchen/kitchenSales.vue";
import allKitchenBills from "../views/kitchen/allKitchenBills.vue";
import VerifyParentIdentity from "../views/SuperVisors/VerifyParentIdentity.vue";

import studentRestrictedMeals from "../views/kitchen/studentRestrictedMeals.vue";
import StudentPurchaseLimit from "../views/kitchen/StudentPurchaseLimit.vue";




import StudentReport from "../views/StudentReport/StudentReport.vue";
import EmployeeReport from "../views/EmployeeReport/EmployeeReport.vue";
import EmployeeWallets from "../views/Walets/EmployeeWallets.vue";
import store from "../store"; // Import Vuex store
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "Dashboard",
      component: Dashboard,
         meta: { 
          requiresAuth: true 
          ,allowedRoles: ["moder","hr","prin","financial","doctor","admin","student_m","cashier","student supervisor"]
         }, // Add meta field for authentication requirement
    }, 
   
    
    
    {
      path: "/send-emails",
      name: "sendEmails",
      component: SendEmails,
         meta: { requiresAuth: true ,allowedRoles: ["moder","hr","prin","admin","student_m","financial"]}, // Add meta field for authentication requirement
    },
    {
      path: "/employee-hour-salary",
      name: "EmployeeHourSalary",
      component: EmployeeHourSalary,
         meta: { requiresAuth: true ,allowedRoles: ["moder","hr","prin","admin","student_m","financial"]}, // Add meta field for authentication requirement
    },
    {
      path: "/attendance-hour-employee",
      name: "AttendanceHourEmployee",
      component: AttendanceHourEmployee,
         meta: { requiresAuth: true ,allowedRoles: ["moder","hr","prin","admin","student_m","financial"]}, // Add meta field for authentication requirement
    },
    {
      path: "/deduction-per-hour",
      name: "DeductionPerHour",
      component: DeductionPerHour,
         meta: { requiresAuth: true ,allowedRoles: ["moder","hr","prin","admin","student_m","financial"]}, // Add meta field for authentication requirement
    },
    {
      path: "/issued-salaries-per-hour",
      name: "IssuedSalariesPerHour",
      component: IssuedSalariesPerHour,
         meta: { requiresAuth: true ,allowedRoles: ["moder","hr","prin","admin","student_m","financial"]}, // Add meta field for authentication requirement
    },
    {
      path: "/verify-parent-identity",
      name: "VerifyParentIdentity",
      component: VerifyParentIdentity,
         meta: { requiresAuth: true ,allowedRoles: ["moder","hr","prin","admin"]}, // Add meta field for authentication requirement
    },

    
    {
      path: "/employee-type",
      name: "EmployeeType",
      component: EmployeeType,
         meta: { requiresAuth: true ,allowedRoles: ["moder","hr","prin"]}, // Add meta field for authentication requirement
    },
    {
      path: "/health-files",
      name: "HealthFile",
      component: HealthFile,
         meta: { requiresAuth: true,allowedRoles: ["moder","doctor"] }, // Add meta field for authentication requirement
    },
    {
      path: "/absence-and-deduction",
      name: "AbsenceDeduction",
      component: AbsenceDeduction,
         meta: { requiresAuth: true ,allowedRoles: ["moder","hr","prin","financial"] }, // Add meta field for authentication requirement
    },
    {
      path: "/all-health-files",
      name: "AllHealthFiles",
      component: AllHealthFiles,
         meta: { requiresAuth: true  ,allowedRoles: ["moder","doctor","prin","student_m"]}, // Add meta field for authentication requirement
    },
    {
      path: "/tables",
      name: "Tables",
      component: Tables,
         meta: { requiresAuth: true }, // Add meta field for authentication requirement
    },
    {
      path: "/billing",
      name: "Billing",
      component: Billing,
         meta: { requiresAuth: true }, // Add meta field for authentication requirement
    },
    {
      path: "/buses",
      name: "Buses",
      component: Buses,
         meta: { requiresAuth: true ,allowedRoles: ["moder","student_m","hr","admin"]}, // Add meta field for authentication requirement
    },
    {
      path: "/print-financial-document",
      name: "PrintFinancialDocument",
      component: FinancialDocumentPrint,
         meta: { requiresAuth: true ,allowedRoles: ["moder","financial"]}, // Add meta field for authentication requirement
    },
    {
      path: "/import-attendance",
      name: "ImportAttendance",
      component: ImportAttendance,
         meta: { requiresAuth: true ,allowedRoles: ["moder","financial","hr"]}, // Add meta field for authentication requirement
    },
    {
      path: "/financial-document",
      name: "FinancialDocument",
      component: FinancialDocument,
         meta: { requiresAuth: true ,allowedRoles: ["moder","financial"]}, // Add meta field for authentication requirement
    },
    {
      path: "/exams",
      name: "Manage Exams",
      component: Exams,
         meta: { requiresAuth: true ,allowedRoles: ["hr", "prin","moder","student_m"]}, // Add meta field for authentication requirement
    },
    {
      path: "/predictions",
      name: "Prediction",
      component: Prediction,
         meta: { requiresAuth: true }, // Add meta field for authentication requirement
    },
    {
      path: "/classes-sections",
      name: "Classes Sections",
      component: ClassesSections,
         meta: { requiresAuth: true ,allowedRoles: ["hr", "prin","moder","student_m"]}, // Add meta field for authentication requirement
    },
    {
      path: "/rtl-page",
      name: "RTL",
      component: RTL,
         meta: { requiresAuth: true }, // Add meta field for authentication requirement
    },
    {
      path: "/notifications",
      name: "Notifications",
      component: Notifications,
         meta: { requiresAuth: true }, // Add meta field for authentication requirement
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
         meta: { requiresAuth: true }, // Add meta field for authentication requirement
    },
    {
      path: "/sign-in",
      name: "SignIn",
      component: SignIn,
    },
    {
      path: "/students",
      name: "Students",
      component: Students,
         meta: { requiresAuth: true ,allowedRoles: ["hr", "prin","moder","student_m"]}, // Add meta field for authentication requirement
    },
    {
      path: "/kitchen",
      name: "kitchen",
      component: kitchen,
         meta: { requiresAuth: true ,allowedRoles: ["moder","financial"]}, // Add meta field for authentication requirement
    },
    {
      path: "/kitchen-sales",
      name: "kitchen sales",
      component: kitchenSales,
         meta: { requiresAuth: true ,allowedRoles: ["moder","cashier"]}, // Add meta field for authentication requirement
    },

    {
      path: "/employee-wallets",
      name: "EmployeeWallets",
      component: EmployeeWallets,
      meta: { requiresAuth: true ,allowedRoles: ["moder","financial"]}, // Add meta field for authentication requirement
    },
  {
      path: "/all-kitchen-bills",
      name: "all Kitchen Bills",
      component: allKitchenBills,
      meta: { requiresAuth: true ,allowedRoles: ["moder","cashier"]}, // Add meta field for authentication requirement
    },
    {
      path: "/student-restricted-meals",
      name: "student Restricted Meals",
      component: studentRestrictedMeals,
      meta: { requiresAuth: true ,allowedRoles: ["moder","financial","student_m","doctor","hr"]}, // Add meta field for authentication requirement
    },

    {
      path: "/student-purchase-limit",
      name: "Student Purchase Limit",
      component: StudentPurchaseLimit,
      meta: { requiresAuth: true ,allowedRoles: ["moder","financial","student_m","hr"]}, // Add meta field for authentication requirement
    },
    
    {
      path: "/treasury-transactions",
      name: "Treasury Transactions",
      component: TreasuryTransactions,
         meta: { requiresAuth: true ,allowedRoles: ["moder"]}, // Add meta field for authentication requirement
    },
    {
      path: "/parent-wallet",
      name: "ParentWallet",
      component: ParentWallet,
         meta: { requiresAuth: true ,allowedRoles: ["hr", "prin","moder","financial"]}, // Add meta field for authentication requirement
    },
    {
      path: "/student-report",
      name: "StudentReport",
      component: StudentReport,
         meta: { requiresAuth: true,allowedRoles: ["hr", "prin","moder","student_m"] }, // Add meta field for authentication requirement
    },
    {
      path: "/employee-reports",
      name: "EmployeeReport",
      component: EmployeeReport,
         meta: { requiresAuth: true,allowedRoles: ["hr", "prin","moder","financial"] }, // Add meta field for authentication requirement
    },
    {
      path: "/treasury",
      name: "Treasury",
      component: Treasury,
         meta: { requiresAuth: true,allowedRoles: ["moder"] }, // Add meta field for authentication requirement
    },
    {
      path: "/activity-log",
      name: "ActivityLog",
      component: ActivityLog,
         meta: { requiresAuth: true,allowedRoles: ["moder"] }, // Add meta field for authentication requirement
    },
    {
      path: "/cards-students",
      name: "Students Cards",
      component: StudentsCards,
         meta: { requiresAuth: true,allowedRoles: ["hr", "student_m","moder"] }, // Add meta field for authentication requirement
    },
    {
      path: "/salary-show",
      name: "Salary Employee",
      component: SalaryShow,
         meta: { requiresAuth: true,allowedRoles: ["hr", "financial","moder"] }, // Add meta field for authentication requirement
    },
    {
      path: "/subscription-fee",
      name: "Subscription Fees",
      component: SubscriptionFee,
         meta: { requiresAuth: true,allowedRoles: ["hr", "financial","moder"] }, // Add meta field for authentication requirement
    },
    {
      path: "/logs-checks",
      name: "Show Log Checks",
      component: LogsCheck,
         meta: { requiresAuth: true ,allowedRoles: ["hr", "financial","moder"]}, // Add meta field for authentication requirement
    },
    {
      path: "/users",
      name: "Users",
      component: Users,
         meta: { requiresAuth: true,allowedRoles: ["moder"]  }, // Add meta field for authentication requirement
    },
    {
      path: "/parents",
      name: "Parents",
      component: Parents,
         meta: { requiresAuth: true,allowedRoles: ["hr", "prin","moder","student_m"] }, // Add meta field for authentication requirement
    },
    {
      path: "/salaries",
      name: "Salary Management",
      component: Salaries,
         meta: { requiresAuth: true,allowedRoles: [ "financial","moder"] }, // Add meta field for authentication requirement
    },
    {
      path: "/manage-salaries",
      name: "Manage Salaries",
      component: ManageSalaries,
         meta: { requiresAuth: true ,allowedRoles: [ "financial","moder"]}, // Add meta field for authentication requirement
    },
    {
      path: "/sign-up",
      name: "SignUp",
      component: SignUp,
         meta: { requiresAuth: true }, // Add meta field for authentication requirement
    },
    {
      path: "/classes",
      name: "classes",
      component: Classes,
      meta: { requiresAuth: true ,allowedRoles: ["hr", "prin","moder","student_m"]}, // Add meta field for authentication requirement
    },
    {
      path: "/departments",
      name: "departments",
      component: Departments,
      meta: { requiresAuth: true ,allowedRoles: ["hr", "prin","moder"]}, // Add meta field for authentication requirement
    },
    {
      path: "/teachers-type-subject",
      name: "TeacherType",
      component: TeacherType,
      meta: { requiresAuth: true,allowedRoles: ["hr", "prin","moder"] }, // Add meta field for authentication requirement
    },
    {
      path: "/school-schedule",
      name: "SchoolSchedule",
      component: SchoolSchedule,
      meta: { requiresAuth: true }, // Add meta field for authentication requirement
    },
    {
      path: "/permission-employee",
      name: "PermissionEmployee",
      component: PermissionEmployee,
      meta: { requiresAuth: true,allowedRoles: ["hr", "prin","moder"] }, // Add meta field for authentication requirement
    },
    {
      path: "/employees",
      name: "Employees",
      component: Employees,
      meta: { requiresAuth: true,allowedRoles: ["hr", "prin","moder"] }, // Add meta field for authentication requirement
    },
    {
      path: "/employee-details",
      name: "EmployeeDetails",
      component: EmployeeDetails,
      meta: { requiresAuth: true ,allowedRoles: ["hr", "prin","moder"]}, // Add meta field for authentication requirement
    },
    {
      path: "/summer-camp-data",
      name: "SummerCampData",
      component: SummerCampData,
      meta: { requiresAuth: true ,allowedRoles: ["hr", "prin","moder"]}, // Add meta field for authentication requirement
    },
    {
      path: "/summer-camp-add",
      name: "SummerCamp",
      component: SummerCamp,
      meta: { requiresAuth: true,allowedRoles: ["hr", "prin","moder"] }, // Add meta field for authentication requirement
    },
    {
      path: "/student-attendances",
      name: "Student Attendances",
      component: Attendances,
      meta: { requiresAuth: true, allowedRoles: ["hr", "prin","moder","teacher","student_m"] }, // accessible by 'hr' or 'admin'
    },
    {
      path: "/get-the-hell-out",
      name: "NotFound",
      component: NotFound,
      meta: { requiresAuth: false}, 
    },
    {
      path: "/employee-form",
      name: "ExtraEmployeeData",
      component: ExtraEmployeeData,
      meta: { requiresAuth: false}, 
    },
    // {
    //   path: "/test-test",
    //   name: "Student Attendances",
    //   component: Attendances,
    //   meta: {
    //     store: store, // Ensure Vuex store is attached to meta
    //   },
    //   beforeEnter: (to, from, next) => {
    //     // Check if Vuex store is attached to route meta
    //     if (to.meta && to.meta.store) {
    //       to.meta.store.dispatch('hideAllElements');
    //     } else {
    //       console.error('Vuex store not attached to route meta!');
    //     }
    //     next();
    //   },
    // },
    {
      path: "/:catchAll(.*)",
      redirect: "/",
    },
  ],
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  if (from.name === 'Attendances' && from.meta && from.meta.store) {
    from.meta.store.dispatch('showAllElements');
  }
  next();
});


// Add navigation guard to check for token
// Navigation guard to check authentication and authorization
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = localStorage.getItem("access_token");
  const userType = localStorage.getItem("user_type");

  if (requiresAuth && !token) {
    return next({ name: "SignIn" });
  }

  if (to.meta.allowedRoles && !to.meta.allowedRoles.includes(userType)) {
    store.commit("setDisplayVisibility", false); // Hide layout elements on 404
    return next({ name: "NotFound" });
  }
  if (to.meta.allowedRoles && !to.meta.allowedRoles.includes(userType)) {
    store.commit("setDisplayVisibility", false); // Hide layout elements on 404
    return next({ name: "ExtraEmployeeData" });
  }

  store.commit("setDisplayVisibility", true); // Show layout elements on other routes
  next();
});


export default router;
