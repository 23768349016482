<template>
    <div class="permission-employee">
      <el-card class="box-card">
        <div slot="header" class="header">
            <span class="title">Permission Management</span>
          
            <!-- Add Permission Button -->
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="small"
              @click="openDialog"
              class="header-btn"
            >
              Add Permission
            </el-button>
          
            <!-- Print Document Button -->
            <el-button
              type="success"
              icon="el-icon-printer"
              size="small"
              @click="printPermissions"
              class="header-btn"
            >
              Print Document
            </el-button>
          </div>
          
        <el-table
          :data="permissions"
          stripe
          border
          style="width: 100%"
          :loading="loading"
        >
          <el-table-column prop="employee.name" label="Employee" width="180" />
          <el-table-column prop="permission_type" label="Type" width="120" />
          <el-table-column label="Status" width="120">
            <template #default="{ row }">
              <el-tag
                :type="getStatusType(row.approval_status)"
                effect="plain"
              >
                {{ getStatusLabel(row.approval_status) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="start_date" label="Start Date" width="150" />
          <el-table-column prop="end_date" label="End Date" width="150" />
          <el-table-column
          fixed="right"
          label="Actions"
          width="280"
          v-slot="{ row }"
        >
          <!-- Edit Button with Icon -->
          <el-button
            type="text"
            size="small"
            @click="editPermission(row)"
          >
          <el-icon> <Edit /></el-icon>
            Edit
          </el-button>
        
          <!-- Delete Button with Icon -->
          <el-button
            type="text"
            size="small"
            @click="deletePermission(row.id)"
          >
            <el-icon><delete /></el-icon>
            Delete
          </el-button>
        
          <!-- Print Button with Icon -->
          <el-button
            type="text"
            size="small"
            @click="printEmployeePermission(row)"
          >
            <el-icon><printer /></el-icon>
            Print
          </el-button>
        </el-table-column>
        
        </el-table>
        <el-pagination
        background
        layout="sizes, prev, pager, next"
        :total="total"
        :current-page="currentPage"
        :page-size="pageSize"
        :page-sizes="[10, 20, 50, 100]"
        @size-change="handleSizeChange"
        @current-change="fetchPermissions"
      />
      
      </el-card>
  
      <!-- Add/Edit Permission Dialog -->
      <el-dialog
        title="Permission Form"
        v-model="dialogVisible"
        width="50%"
      >
        <el-form
          ref="permissionForm"
          :model="form"
          :rules="rules"
          label-width="120px"
        >
          <el-form-item label="Employee" prop="employee_id">
            <el-select
              v-model="form.employee_id"
              filterable
              remote
              :remote-method="fetchEmployees"
              placeholder="Select Employee"
            >
              <el-option
                v-for="employee in employees"
                :key="employee.id"
                :label="employee.name"
                :value="employee.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Type" prop="permission_type">
            <el-input v-model="form.permission_type" />
          </el-form-item>
          <el-form-item label="Request Date" prop="request_date">
            <el-date-picker
              v-model="form.request_date"
              type="date"
              placeholder="Pick a date"
                @change="onRequestDateChange"
            />
          </el-form-item>
          <el-form-item label="Status" prop="approval_status">
            <el-select v-model="form.approval_status" placeholder="Select Status">
              <el-option label="Approved" value="approved" />
              <el-option label="Rejected" value="rejected" />
              <el-option label="Under Review" value="under_review" />
            </el-select>
          </el-form-item>
          <el-form-item label="Start Date" prop="start_date">
            <el-date-picker
              v-model="form.start_date"
              type="date"
              placeholder="Pick a date"
              @change="onStartDateChange"
            />
          </el-form-item>
          <el-form-item label="End Date" prop="end_date">
            <el-date-picker
              v-model="form.end_date"
              type="date"
              placeholder="Pick a date"
              @change="onEndDateChange"
            />
          </el-form-item>
          <el-form-item label="Reason" prop="reason">
            <el-input type="textarea" v-model="form.reason" />
          </el-form-item>
          <el-form-item label="Remarks" prop="remarks">
            <el-input type="textarea" v-model="form.remarks" />
          </el-form-item>
          <el-form-item label="File Attachment" prop="file_attachment">
            <el-upload
              action=""
              :file-list="fileList"
              :auto-upload="false"
              :on-change="handleFileChange"
            >
              <el-button type="primary">Upload File</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="submitForm">Submit</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  import { Printer,Delete,Edit } from '@element-plus/icons-vue';
  import axios from 'axios';
  const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token'); // Get token from local storage
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`; // Add token to Authorization header
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

  export default {
    components:{
        Printer,
        Edit,
        Delete,
    },
    data() {
      return {
        permissions: [],
        employees: [],
        form: {
          employee_id: null,
          permission_type: '',
          request_date: '',
          approval_status: '',
          start_date: '',
          end_date: '',
          reason: '',
          remarks: '',
          file_attachment: null,
        },
        fileList: [],
        rules: {
          employee_id: [{ required: true, message: 'Employee is required', trigger: 'change' }],
          permission_type: [{ required: true, message: 'Permission type is required', trigger: 'blur' }],
          request_date: [{ required: true, message: 'Request date is required', trigger: 'change' }],
        },
        dialogVisible: false,
        loading: false,
        total: 0,
        currentPage: 1,
        pageSize: 10,
      };
    },
    methods: {
        printEmployeePermission(row) {
      const printWindow = window.open("", "_blank");
      const employeePermissionHtml = `
      <html>
        <head>
          <style>
            /* General body styles */
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
            }

            /* Header Styling */
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 30px;
              padding: 10px 20px;
              background-color: #004080;
              color: white;
              border-radius: 8px;
            }

            .header .title {
              font-size: 28px;
              font-weight: bold;
            }

            .logo {
              height: 60px;
              margin-right: 10px;
            }

            /* Formal Letter */
            .content {
              margin: 20px 0;
              font-size: 16px;
              line-height: 1.8;
            }

            /* Footer */
            .footer {
              position: absolute;
              bottom: 40px;
              width: 100%;
              display: flex;
              justify-content: space-between;
              padding: 10px 0;
              border-top: 1px solid #333;
            }

            .signature-section {
              width: 45%;
              text-align: center;
            }

            .signature-line {
              margin-top: 40px;
              border-top: 1px solid #333;
              width: 60%;
              margin-left: auto;
              margin-right: auto;
            }
              .content-ar {
  direction: rtl;
  text-align: right;
  font-family: "Tahoma", sans-serif;
  margin: 10px 0;
}

          </style>
        </head>
       <body>
  <!-- Styled Header Section -->
  <div class="header">
    <div class="title">Caledonian International School</div>
    <img src="/logo-school-one.png" alt="Logo" class="logo" />
  </div>

  <!-- Letter Content Section - English -->
  <div class="content">
    <p><strong>Date:</strong> ${new Date().toLocaleDateString()}</p>
    <p><strong>To:</strong> ${row.employee.name}</p>
    <p><strong>Subject:</strong> Permission Request</p>
    <p>
      Dear ${row.employee.name},<br><br>
      This is to confirm that you have been granted permission for the type: <strong>${row.permission_type}</strong>.
      The period of this permission extends from <strong>${row.start_date ? row.start_date : 'N/A'}</strong> 
      to <strong>${row.end_date ? row.end_date : 'N/A'}</strong>.
    </p>
    <p>
      If you have any further inquiries or clarifications regarding this matter, please contact the HR department at your convenience.
    </p>
    <p>Sincerely,</p>
  </div>

  <!-- Letter Content Section - Arabic -->
  <div class="content-ar">
    <p><strong>التاريخ:</strong> ${new Date().toLocaleDateString()}</p>
    <p><strong>إلى:</strong> ${row.employee.name}</p>
    <p><strong>الموضوع:</strong> طلب إذن</p>
    <p>
      عزيزي/عزيزتي ${row.employee.name},<br><br>
      نود إبلاغكم بأنه تم منحكم إذنًا من نوع: <strong>${row.permission_type}</strong>.
      مدة الإذن تبدأ من <strong>${row.start_date ? row.start_date : 'N/A'}</strong> 
      إلى <strong>${row.end_date ? row.end_date : 'N/A'}</strong>.
    </p>
    <p>
      إذا كانت لديكم أي استفسارات أو توضيحات بخصوص هذا الموضوع، يُرجى التواصل مع قسم الموارد البشرية.
    </p>
    <p>مع أطيب التحيات،</p>
  </div>

  <!-- Signature Section -->
  <div class="footer">
    <div class="signature-section">
      <div class="signature-line"></div>
      <p>HR Representative</p>
    </div>
    <div class="signature-section">
      <div class="signature-line"></div>
      <p>Employee</p>
    </div>
  </div>
</body>

      </html>
    `;
      printWindow.document.write(employeePermissionHtml);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    },
        printPermissions() {
  const printWindow = window.open("", "_blank");
  const tableHtml = `
    <html>
      <head>
        <style>
          /* General body styles */
          body {
            font-family: Arial, sans-serif;
            margin: 20px;
          }

          /* Header Styling */
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
            padding: 10px 20px;
            background-color: #004080; /* Dark blue background */
            color: #ffffff; /* White text */
            border-radius: 8px;
          }

          /* Title in the Header */
          .header .title {
            font-size: 36px;
            font-weight: bold;
            text-align: center;
          }

          /* Logo Styling */
          .logo {
            height: 100px;
            margin-right: 10px;
          }

          /* Date Styling */
          .date {
            font-size: 18px;
            margin-left: 10px;
          }

          /* Table Styles */
          table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 10px;
          }

          th, td {
            border: 1px solid #000;
            padding: 10px;
            text-align: left;
            font-size: 14px;
          }

          th {
            background-color: #f4f4f4;
          }
        </style>
      </head>
      <body>
        <!-- Styled Header Section -->
        <div class="header">
          <div class="date">${new Date().toLocaleDateString()}</div>
          <div class="title">Caledonian International School</div>
          <img src="/logo-school-one.png" alt="Logo" class="logo" />
        </div>
        
        <!-- Table Section -->
        <table>
          <thead>
            <tr>
              <th>Employee</th>
              <th>Type</th>
              <th>Status</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            ${this.permissions
              .map(
                (permission) => `
              <tr>
                <td>${permission.employee.name}</td>
                <td>${permission.permission_type}</td>
                <td>${this.getStatusLabel(permission.approval_status)}</td>
                <td>${permission.start_date}</td>
                <td>${permission.end_date}</td>
              </tr>
            `
              )
              .join("")}
          </tbody>
        </table>
      </body>
    </html>
  `;
  printWindow.document.write(tableHtml);
  printWindow.document.close();
  printWindow.focus();
  printWindow.print();
},

  
        formatDate(value) {
     // This method formats the date to YYYY-MM-DD
     if (!value) return ''; // Handle empty values safely
  const d = new Date(value);
  
  // Format date as YYYY-MM-DD without shifting due to time zone
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(d.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
    },
    getStatusType(status) {
    switch (status) {
      case 'approved':
        return 'success'; // Green color for approved
      case 'rejected':
        return 'danger'; // Red color for rejected
      case 'under_review':
        return 'warning'; // Yellow color for under review
      default:
        return ''; // Default for unknown statuses
    }
  },
  getStatusLabel(status) {
    switch (status) {
      case 'approved':
        return 'تمت الموافقة'; // Label for approved
      case 'rejected':
        return 'مرفوضة'; // Label for rejected
      case 'under_review':
        return 'تحت المراجعة'; // Label for under review
      default:
        return 'Unknown'; // Default for unknown statuses
    }
  },
    onRequestDateChange(value) {
    this.form.request_date = this.formatDate(value); // Apply formatting
  },
  
    onStartDateChange(value) {
    this.form.start_date = this.formatDate(value); // Apply formatting
  },
    onEndDateChange(value) {
    this.form.end_date = this.formatDate(value); // Apply formatting
  },
  async fetchPermissions(page = 1) {
    this.loading = true;
    try {
        const { data } = await api.get(`/permissions?page=${page}`);
        this.permissions = data.data; // Assign paginated data
        this.total = data.total; // Total number of items
        this.currentPage = data.current_page; // Current page number
        this.loading = false;
    } catch (error) {
        this.loading = false;
        console.error("Error fetching permissions:", error);
    }
},
      async fetchEmployees(query) {
        const { data } = await api.get(`permissions/employees?search=${query}`);
        this.employees = data;
      },
      openDialog() {
        this.dialogVisible = true;
        this.form = {};
      },
      editPermission(permission) {
        this.dialogVisible = true;
        this.form = { ...permission };
      },
      async deletePermission(id) {
        await api.delete(`/permissions/${id}`);
        this.fetchPermissions(this.currentPage);
      },
      handleFileChange(file) {
        this.form.file_attachment = file.raw;
      },
      async submitForm() {
        const formData = new FormData();
        for (const key in this.form) {
          formData.append(key, this.form[key]);
        }
        if (this.form.id) {
          await api.put(`/permissions/${this.form.id}`, formData);
        } else {
          await api.post('/permissions', formData);
        }
        this.dialogVisible = false;
        this.fetchPermissions(this.currentPage);
      },
      handleSizeChange(size) {
    this.pageSize = size;
    this.fetchPermissions(1); // Fetch the first page with the new size
},
    },
    mounted() {
      this.fetchPermissions();
    },
  };
  </script>
 <style scoped>
 .permission-employee {
   padding: 20px;
 }
 
 /* Header Styling */
 .header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 10px 20px;
   border-bottom: 2px solid #e4e7ed;
 }
 
 /* Title in Header */
 .title {
   font-size: 18px;
   font-weight: bold;
   margin-right: auto;
 }
 
 /* Header Button Common Styles */
 .header-btn {
   margin-left: 10px;
 }
 </style>
 
    